<template>
  <div class="container">
    <nav-box :title="showTitle" :back="false"></nav-box>
    <div class="content flex-column">
      <div class="win-title flex flex-center">
        <div class="icon flex">
          <van-icon name="checked" v-if="result" size="88" color="#2da641" />
          <van-icon name="clear" v-else size="88" color="#D40000" />
        </div>
        <span class="tip">{{ showTitle }}</span>
      </div>
      <div class="print flex flex-center">
        <img src="../../../assets/print.png" alt="" />
      </div>
      <p class="hint">正在出票中，请及时领取小票</p>
      <div class="btn flex flex-center" @click="goWelcome">
        返回首页（{{ time }}S）
      </div>
    </div>
  </div>
</template>

<script>
import navBox from "@/components/nav-box.vue";
export default {
  components: {
    navBox,
  },
  name: "result",
  data() {
    return {
      title: "",
      time: 0,
      result: false,
      payment: "",
      timerId: "",
    };
  },
  created() {
    this.result = this.$route.query.result;
    this.payment = this.$route.query.payType;
  },
  computed: {
    showTitle() {
      if (this.result && this.payment == "payment") {
        return "支付成功";
      }
      if (this.result == false && this.payment == "payment") {
        return "支付失败";
      }
      if (this.result && this.payment == "ticket") {
        return "核销成功";
      }
      if (this.result == false && this.payment == "ticket") {
        return "核销失败";
      }
      return "";
    },
  },
  mounted() {
    this.time = 10;
    this.timerId = setInterval(this.countDown, 1000);
  },
  watch: {
    time: function (newVal, oldVal) {
      if (newVal == 0) {
        localStorage.setItem("keyCard", "");
        if (localStorage.getItem("type") == "先付模式") {
          this.$router.push("/prepayment");
        } else {
          this.$router.push("/welcome");
        }
      }
    },
  },
  methods: {
    countDown() {
      this.time--;
    },
    goWelcome() {
      localStorage.setItem("keyCard", "");
      if (localStorage.getItem("type") == "先付模式") {
        this.$router.push("/prepayment");
      } else {
        this.$router.push("/welcome");
      }
    },
  },
  beforeDestroy() {
    // 清除计时器
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.content {
  padding: 180px 197px 0 197px;
  align-items: center;
  justify-content: center;

  .tip {
    font-size: 60px;
    line-height: 88px;
    color: #1a1e22;
    margin-left: 28px;
    font-weight: 500;
  }
  .win-title {
    margin-bottom: 154px;
  }
  .print {
    margin-bottom: 24px;
    img {
      width: 446px;
      height: 360px;
    }
  }
  .hint {
    font-size: 44px;
    line-height: 52px;
    color: #1a1e22;
    font-weight: 500;
  }
  .btn {
    width: 100%;
    height: 140px;
    font-size: 40px;
    color: #fff;
    background: #155bd4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    margin-top: 200px;
  }
}
</style>